import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Load Board (MVP)",
  "subtitle": "2020",
  "tags": "Design, UX",
  "summary": "A simple web app to test an idea for replacing a traditional email and phone based truck matching process, enhancing the transfer of business information.",
  "date": "2020",
  "order": 4,
  "featuredImage": "./loadboard/cover.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "about-the-initiative"
    }}>{`About the initiative`}</h2>
    <p>{`A leading flatbed trucking company and freight hauler with more than 30 offices wanted to streamline their truck matching process that was previously done exclusively with phone calls and emails. This web application would allow individual accounts to easily post availability from various brokers. A minimal first version was built to test the idea with selected group of staff.`}</p>
    <h3 {...{
      "id": "team"
    }}>{`Team`}</h3>
    <ul>
      <li parentName="ul">{`Product & UX Design (me)`}</li>
      <li parentName="ul">{`2 Engineers (including me)`}</li>
      <li parentName="ul">{`Group of testers`}</li>
    </ul>
    <h2 {...{
      "id": "improving-the-user-journey"
    }}>{`Improving the user journey`}</h2>
    <p>{`Coordinating had been done manually since day one so there was no standard template or language used. Many of them wrote with different styles so when times were busy, it became difficult to sort. Depending on the preferred email client, staff members also had to navigate different interfaces.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "596px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Original user flow",
          "title": "Original user flow",
          "src": "/static/8a8c8118eb257c23e84ee98b79c4fa78/ad3a2/user_flow.png",
          "srcSet": ["/static/8a8c8118eb257c23e84ee98b79c4fa78/a3397/user_flow.png 400w", "/static/8a8c8118eb257c23e84ee98b79c4fa78/ad3a2/user_flow.png 596w"],
          "sizes": "(max-width: 596px) 100vw, 596px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "design-process"
    }}>{`Design process`}</h2>
    <p>{`Since the goal was not a fancy design but to help smooth out workflow, I prioritized organizing the information structure so that it would be easy to read at a glance.`}</p>
    <h4 {...{
      "id": "prioritizing-information"
    }}>{`Prioritizing information`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{` - The first thing that came to mind was to find a consistent way to display the locations of origin and destination as this was the primary information necessary to facilitate any activity.`}</li>
      <li parentName="ul"><strong parentName="li">{`Price`}</strong>{` - There were two types, either a gross earning or a take-home amount. This was an important factor for drivers to determine if the trip would be worth the cost.`}</li>
      <li parentName="ul"><strong parentName="li">{`Contact information`}</strong>{` - As the industry is deeply routed in tradition, a phone call is often the best way to pick up the trip. Relationships between companies and drivers are also often taken into account so it is important to display who the request came from.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1240px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Initial card display ideas",
          "title": "Initial card display ideas",
          "src": "/static/8648aee2da756d9930adb6e589db50f7/9dbfc/design_initial.png",
          "srcSet": ["/static/8648aee2da756d9930adb6e589db50f7/a3397/design_initial.png 400w", "/static/8648aee2da756d9930adb6e589db50f7/a331c/design_initial.png 800w", "/static/8648aee2da756d9930adb6e589db50f7/9dbfc/design_initial.png 1240w"],
          "sizes": "(max-width: 1240px) 100vw, 1240px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Although the designs were well received, it took up a lot of vertical space which meant that a staff member could get more from reading subject headlines in an email client than these designs. This web application was built primary for desktop use so I iterated the design to utilize more horizontal real estate while still prioritizing readability.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "780px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "95.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Design iterations",
          "title": "Design iterations",
          "src": "/static/b603f544b212234d0c54ab1ca3dd856e/42e17/design_iterations.png",
          "srcSet": ["/static/b603f544b212234d0c54ab1ca3dd856e/a3397/design_iterations.png 400w", "/static/b603f544b212234d0c54ab1ca3dd856e/42e17/design_iterations.png 780w"],
          "sizes": "(max-width: 780px) 100vw, 780px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "business-considerations"
    }}>{`Business considerations`}</h2>
    <p>{`One of the top requests was to allow as much information to be displayed as possible on the screen. How do we know how many to display? I asked a staff member to gather relevant data on several data points such as, how many requests were normally received per hour. I used this to tighten the spacing for a UX trial.`}</p>
    <h3 {...{
      "id": "loads"
    }}>{`Loads`}</h3>
    <p>{`This was the primary screen that the staff would use to do their jobs.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "78.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB6klEQVQ4y4VTCZKcMAyc/79ykxlufMnmsDk6JYEnhMzWUmWQDWq1Ws0D2LGsO/wMhHgsN+7Y958X5/K6nj34tq4rvB9A5OF9QIzpBzDINaUdNG3Ytgsgv1iWBWVZoCheqKoKMc6SsJ+Z27a94/z9khLcuKKl9Z93AritK8IwgnzAui5vsPxhjFG6yMDee9R9D+cJ5EnOc84jJylDeFUdyDkMw4B5ngXIOYe+70FEmKYJKSUk7ohmlMpDO5KWL4ASwjqPulUCEEIQUE4ex1EYZTBuNaaE0hAa7WDJf8fQ4etZomkaaK0FhEG5AO9zgYNhQmMHYTjOEdt+ZXhEcGGCpSCMmA23zMn85H0+TyfDNkxoDKEzFutnhhavokTXdVBKiWbMivXjyfO5tVYK8JQrE9BowjBNnzVU1uNZtnDWChMeCCczMBfILTOYaGgDam2hnL8xPFv2YUCnjABkFrwYnMG4ZS6Sp6xogKZPGp6e1NbjV3EwzKCczDG3+gY7NSzciMYctrlpeNqGAuq2hzVGbJPbY2a8vwOybWplYOg/H/61zbOo0N5sw0NhK91ZVmZAZwOmmG4+PDWkMENZ1urQK/8pmWH+e8RKKaH242kb941ttMHX76dY5KohG5sZZivJOdtGB2HI8XUofwCdPt1sdH/uTwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Loads dashboard",
          "title": "Loads dashboard",
          "src": "/static/5dab77b8f80158469171125c7bb8bd66/658fc/ui_loads.png",
          "srcSet": ["/static/5dab77b8f80158469171125c7bb8bd66/a3397/ui_loads.png 400w", "/static/5dab77b8f80158469171125c7bb8bd66/a331c/ui_loads.png 800w", "/static/5dab77b8f80158469171125c7bb8bd66/658fc/ui_loads.png 1024w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "trucks"
    }}>{`Trucks`}</h3>
    <p>{`After some discussion, we agreed that there wasn't a huge difference in what a staff member needed to know about trucks. Rather than using an entirely different interface as it would add time to develop, I chose to remove several elements and reused the frame structure. I also saw this as a way to ease the on-boarding learning curve for this system.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "78.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAABsklEQVQ4y4VSi3KjMAzM//9ke9OQkBaCn8gv2I5EnHEIN2VmsbDRerXSCViRywobABc3aFqxrn+Dcxnt3olfpRRY62GMhXMeFOIfZJCH0gpDC5alIeSDnDMulw5dd0bf9/DeS8L6yFyW5RnX/3OKUD6hV/l5xm8hXEqB8zO0cUgpPckqQghCUomdcxjGCSEQcgqyX3NOlf2uLbp+BBEhxigkLXivgi3SPsLPYXc5mHATq4zD9TZAawVrrYBLn+dZFPFFrJIr4DWmjJRL06C9wknhfOlxu/XQWgtRS8ArI8aEkjMmQ7CemGVHuEXQLkAZj0AkJVaCI/AFFBJCTEJy6OHPMODj4xPDMGAcRxhjpGQul8FxRc4Jkw0wPrxMw3NsNoWEu54RH+raMltwU/jMUkFIi/h/3GVlcO6uorA2pCbXC9p4piB4U1g/xsmiu35DqUnI6OHlEbjkTWGR6l4U1oBVDcMIpdSLXxWtj6xyU0j/H5ufu8G/800I94PMaH3lVfsER/ndwxo4T5iUEaU8g3uFvNeOjfURc4gHc1gVjhqfX1cZmaryeLC3Rk2W4Gd68/AXSnjkmL5G/qUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Trucks dashboard",
          "title": "Trucks dashboard",
          "src": "/static/5cb7655c0e6fe8ff5d3a47e7ed24c596/658fc/ui_trucks.png",
          "srcSet": ["/static/5cb7655c0e6fe8ff5d3a47e7ed24c596/a3397/ui_trucks.png 400w", "/static/5cb7655c0e6fe8ff5d3a47e7ed24c596/a331c/ui_trucks.png 800w", "/static/5cb7655c0e6fe8ff5d3a47e7ed24c596/658fc/ui_trucks.png 1024w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "users"
    }}>{`Users`}</h3>
    <p>{`Few staff members were going to be managing users and to prevent our MVP from ballooning, I also chose to reuse styling until we revisit the feature in the future.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "78.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAABwUlEQVQ4y4VTiZKrIBDM/3/ivq3kZY8kRgSZQZBB7S0wZpNsjqlq5ZCmp2dcARPSMIF7FFAY0foR0zS9RD6bcbm2yo9hGEDs4EOPYZwKnpOhRJCpCBgvvl/ljZQSDocD6ro+3Xwd4zherac0YJAI2yUcabjaWy0HmBnWWvQhnNL4TTuEUC5diNk57GsFcg7suKznOKecQzUa7+stGm0R+oQuRIQ+QiRDCmKMkBgRJStzOBoL1dJZ4YmwDGEs43OvoKyHcQLNEewFKcmZMCOJoI+Cmj2o82jZYbwmnCeNIfzbfMNSh3FIheiW7KxUBA11hYx9uFU4T4gYtWpmFQ+ILhUe2UHlM8beKlxSdvjYKdRtTmX26j5pLAo1d7Ds0IUHCk1LWH/ssKs0fJ9eKqxd9tpDWbrvYUsO2+8aR519eUy4eLgodMVD/CXU2mC92YK4K20R76QcT+8+RuxbQqVb1Ka9UTiPQC7g0DCUDSAvLxXaIPAxwvfxvodHpfG++YQy/NRDWapsuTS1tg8a2xLja1fBsi8pD+lJUURQkUNDjOZvUWZCZQhv//eotINmgXXzb5e9vERup6xQUQdDfCrKL+EPLLzi6YHySBIAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Users dashboard",
          "title": "Users dashboard",
          "src": "/static/d7a50ae336e740f0927e842f4ff81098/658fc/ui_users.png",
          "srcSet": ["/static/d7a50ae336e740f0927e842f4ff81098/a3397/ui_users.png 400w", "/static/d7a50ae336e740f0927e842f4ff81098/a331c/ui_users.png 800w", "/static/d7a50ae336e740f0927e842f4ff81098/658fc/ui_users.png 1024w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "search--filters"
    }}>{`Search & filters`}</h3>
    <p>{`One of the top enhancements for using this system over email, is to allow relevant data to be searched and sorted without additional noise.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Searching",
          "title": "Searching",
          "src": "/static/1e2e9f11aefeea5a0e98b1d756a18921/658fc/ui_search.png",
          "srcSet": ["/static/1e2e9f11aefeea5a0e98b1d756a18921/a3397/ui_search.png 400w", "/static/1e2e9f11aefeea5a0e98b1d756a18921/a331c/ui_search.png 800w", "/static/1e2e9f11aefeea5a0e98b1d756a18921/658fc/ui_search.png 1024w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "crud"
    }}>{`CRUD`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1500px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "CRUD",
          "title": "CRUD",
          "src": "/static/33dc322efe40f00ec126450da46bbf01/de2df/ui_crud.png",
          "srcSet": ["/static/33dc322efe40f00ec126450da46bbf01/a3397/ui_crud.png 400w", "/static/33dc322efe40f00ec126450da46bbf01/a331c/ui_crud.png 800w", "/static/33dc322efe40f00ec126450da46bbf01/de2df/ui_crud.png 1500w"],
          "sizes": "(max-width: 1500px) 100vw, 1500px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "takeaway"
    }}>{`Takeaway`}</h2>
    <p>{`My philosophy has always been to help users solve their problem first. Once we know that, we can easily update the design skin to reflect any theme we choose. There were many enhancement features such as auto matching empty trucks with loads that could really alleviate a lot of manual processes but I think it's best to first make sure we were building on top of good ground work.`}</p>
    {
      /* On the computer at desks at work. Plus in the morning/evening from phones like they check email. Basically they do this over email right now with an internal group and we’re moving it to a web app to clean up their inboxes and in the future enable better features like automatching empty trucks with loads that need trucks --> */
    }
    {
      /* User actions
      - User signin/signout with email/password
      - User forgot password resets via email
      Load board
      - Home page shows a list of all loads and empty trucks added by users
      - Users can add/edit/delete loads
      - Users can add/edit/delete empty trucks
      - Users can only edit/delete loads and empty trucks which they have created
      Admin management
      - Admins can edit/delete any load regardless of who created it
      - Admins can invite users to the application
      - Admins can edit/remove users */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      